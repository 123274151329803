<template>
  <div class="skeleton" v-if="loading">
    <div class="margin-top-100 skeleton-item">
      <div class="skeleton-title"></div>
    </div>
    <div class="margin-top-10 skeleton-item">
      <div class="skeleton-detail"></div>
    </div>
    <div class="skeleton-round-box">
      <div class="skeleton-round"></div>
      <div class="skeleton-text margin-top-10"></div>
    </div>
    <div class="skeleton-text margin-top-10"></div>

    <div class="skeleton-bottom">
      <div class="skeleton-left"></div>
      <div class="skeleton-right"></div>
    </div>
  </div>
  <div v-else>实际内容</div>
</template>
<script>
import { ref, onMounted } from "vue";
export default {
  setup() {
    const loading = ref(true);

    onMounted(() => {
        // setTimeout(() => {
        //   loading.value = false;
        // }, 1000);
    });

    return {
      loading,
    };
  },
};
</script>
<style lang="scss" scoped>
.skeleton {
  width: 100%;
  height: 100%;
  position: relative;
  .skeleton-item {
    display: flex;
    justify-content: center;
  }
  .skeleton-title {
    border-radius: 4px;
    width: 80%;
    height: 50px;
    background-color: #e5e5e5;
    animation: skeleton-animation 1.2s ease-in-out infinite;
  }
  .skeleton-detail {
    border-radius: 4px;
    width: 60%;
    height: 20px;
    background-color: #e5e5e5;
    animation: skeleton-animation 1.2s ease-in-out infinite;
  }
  .skeleton-round-box {
    width: 100%;
    height: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .skeleton-round {
      width: 100px;
      height: 100px;
      background-color: #e5e5e5;
      border-radius: 50%;
      animation: skeleton-animation 1.2s ease-in-out infinite;
    }
    .skeleton-text {
      animation: skeleton-animation 1.2s ease-in-out infinite;
      background-color: #e5e5e5;
      width: 54px;
      height: 18px;
      border-radius: 4px;
    }
  }
  .margin-top-50 {
    margin-top: 50px;
  }
  .margin-top-100 {
    margin-top: 100px;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .skeleton-bottom {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    height: 98px;
    width: 100%;
    padding: 13px 16px;
    display: flex;
    justify-content: space-between;
    .skeleton-left {
      width: 110px;
      height: 48px;
      padding: 13px 20px;
      color: #333333;
      font-size: 16px;
      border-radius: 24px;
      border: #e5e5e5 solid 1px;
    }
    .skeleton-right {
      flex: 1;
      margin-left: 13px;
      height: 48px;
      padding: 13px 20px;
      color: #333333;
      font-size: 16px;
      border-radius: 24px;
      background-color: #e5e5e5;
      animation: skeleton-animation 1.2s ease-in-out infinite;
    }
  }
}

@keyframes skeleton-animation {
  50% {
    opacity: 0.6;
  }
}
</style>
